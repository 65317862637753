<template>
    <div>
        <div
            v-if="show_email"
            class="paper page_main_col col-12 offset-lg-1 col-lg-10 py-4 mb-5 text-left"
        >
            <div>
                <h1>Alternative Login Method</h1>
                <p>
                    We're sorry, but our normal login process is not working. We are actively
                    pursuing a solution with Desjardins corporate which we hope will restore the
                    normal login functionality as soon as possible.
                </p>
                <p>
                    In the meantime, if you have previously visited the site, you may login again
                    below by entering your email address. This will generate an email message to
                    your Inbox that contains a link back to your dashboard which you can use to
                    login.
                </p>
                <p>
                    As always, if you need assistance and are unable to use our website to
                    accomplish something, you can contact our support at
                    <strong>
                        <a href="mailto:support@mydadomain.com">support@mydadomain.com</a>
                    </strong>
                    or by calling
                    <strong> <a href="tel:1-877-772-1982">1-877-772-1982</a>. </strong>
                </p>
            </div>
            <h4 class="mt-5">Login via Email</h4>
            <div class="input-group mb-3">
                <input
                    type="text"
                    id="email_address"
                    class="form-control"
                    :class="email_error ? 'is-invalid' : null"
                    @input="email_error = null"
                    v-model="email_address"
                    placeholder="Enter your @desjardins.com email address..."
                    aria-label="Enter your @desjardins.com email address..."
                    :disabled="email_loading"
                />
                <div class="input-group-append">
                    <b-button
                        variant="primary"
                        @click="send_email"
                        :disabled="!email_address || email_loading"
                    >
                        <span v-if="email_loading">
                            <Icon i="spinner" spin />&nbsp;Sending...
                        </span>
                        <span v-else>Send Login Email</span>
                    </b-button>
                </div>
            </div>
            <b-alert :show="email_error" variant="danger">{{ email_error }}</b-alert>
            <b-alert :show="email_done" variant="success"
                >Login Message Sent! Check your inbox!</b-alert
            >

            <h4 class="mt-5">Login via Single-Sign-On (SSO)</h4>
            <p>While it probably won't work at the moment, you can still try the SSO login below</p>
            <div class="d-flex justify-content-end">
                <b-button variant="outline-primary" @click="initiate_backend_redirect"
                    >Login via SSO</b-button
                >
            </div>
        </div>
        <div v-else class="mx-auto">
            <h1 class="mt-5 display-4 text-uppercase text-muted">
                <Icon i="spinner" spin />Logging In!
            </h1>
            <p class="text-muted">please wait a moment while we log you in now</p>
        </div>

        <b-modal
            :visible="Boolean(login_error)"
            centered
            size="lg"
            hide-header
            hide-footer
            ok-title="Confirm"
            @hide.prevent="onHide"
        >
            <h3>Something went wrong</h3>
            <p>{{ login_error }}</p>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios';

const standard_error_message = 'The login process did not complete successfully';

export default {
    name: 'Login',

    data: function () {
        return {
            login_error: null,

            sso_loading: false,
            email_status: false,
            email_error: null,
            email_address: null,
        };
    },
    computed: {
        show_email() {
            // uncomment this line for email login
            // return window.location.hash.length == 0 && !this.sso_loading;
            return false;
        },
        email_loading() {
            return this.email_status == 'loading';
        },
        email_done() {
            return this.email_status == 'done';
        },
    },
    created: function () {
        if (window.location.hash) {
            // parse the url hash
            const data = window.location.hash.replace('#', '');
            let payload = {};
            data.split('&').forEach((i) => {
                const [key, item] = i.split('=');
                payload[key] = item;
            });

            // cognito has returned to our landing page whith an error message
            if (payload.error) {
                const { error_description, error } = payload;
                this.login_error = standard_error_message;
                if (this.$sentry) {
                    this.$sentry.captureMessage(
                        `Got error response from Cognito: ${error} - ${error_description.replace(
                            /\+/g,
                            ' ',
                        )}`,
                    );
                }
            } else {
                // no error message in hash, proceed with authentication
                axios
                    .post(`/backend/authenticate/`, payload)
                    .then((resp) => {
                        this.manage_logged_in_redirect();
                    })
                    .catch((err) => {
                        if (err.response.data && err.response.data.error_reason == 'email') {
                            this.login_error = `The email address "${err.response.data.error_detail}" is not allowed`;
                        } else {
                            this.login_error = standard_error_message;
                        }
                    });
            }
        } else if (window.location.search.indexOf('?email_login_token=') >= 0) {
            const token = window.location.search.replace('?email_login_token=', '');
            const payload = {
                email_login_token: token,
            };
            axios
                .post(`/backend/authenticate/`, payload)
                .then((resp) => {
                    this.manage_logged_in_redirect();
                })
                .catch((err) => {
                    this.login_error = standard_error_message;
                });
        } else if (window.location.search.indexOf('?mirus_login') >= 0) {
            this.initiate_backend_redirect(true);
        } else {
            // comment this line for email login
            this.initiate_backend_redirect();
        }
    },
    methods: {
        initiate_backend_redirect(is_staff) {
            this.sso_loading = true;
            const is_staff_string = is_staff ? '?target=mirus' : '';
            axios.get(`/backend/login_url/${is_staff_string}`).then((resp) => {
                const url = `${resp.data.login_url}&redirect_uri=${window.location.protocol}//${window.location.host}/login`;
                window.location.href = url;
            });
        },
        manage_logged_in_redirect() {
            if (window.localStorage.destination_url) {
                const dest = window.localStorage.destination_url;
                window.localStorage.destination_url = '';
                const match = dest.match(/next=%2F([^%]*)(%2F)?/);
                const dest_str = match ? match[1] : null;

                if (!dest_str) {
                    window.location = '/';
                } else {
                    window.location = '/' + dest_str;
                }

            } else {
                this.$store.dispatch('get_user_bundle');
                window.location = '/';
            }
        },
        send_email() {
            this.email_status = 'loading';
            this.email_error = null;
            axios
                .post(`/backend/login_email/`, { email: this.email_address })
                .then((resp) => {
                    this.email_status = 'done';
                    if (!resp.data.success) {
                        this.email_error = resp.data.reason;
                        this.email_status = null;
                    }
                })
                .catch((err) => {
                    this.login_error = standard_error_message;
                    this.email_status = null;
                });
        },
        onHide() {
            // pranked! you can't hide this dialog
        },
    },
};
</script>
<style lang="scss" scoped></style>
