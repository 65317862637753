<template>
    <div>
        <div class="mx-auto">
            <h1 class="mt-5 display-4 text-uppercase text-muted">403</h1>
            <p class="text-muted">Huh, looks like you don't have access to this page</p>
            <p><a href="/" class="dark-green">back to the dashboard</a></p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FourOhThree',

    data: function () {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.dark-green {
    color: $dark-green;
}
</style>
