<template>
    <div class="mx-auto col-12">
        <h1 class="mt-5 display-4 text-uppercase text-muted">No Account Selected</h1>
        <p><Icon i="spinner" spin />&nbsp; Redirecting to <a href="/accounts/">Account Selector</a></p>
    </div>
</template>
<script>
export default {
    name: 'AccountSelector',
    components: {},
    beforeMount() {
        setTimeout(() => {
            window.location = '/accounts/';
        }, 3000);
    },
};
</script>
<style lang="scss" scoped>
.page_main_col {
    padding: 15px;
    text-align: left;
}
</style>
